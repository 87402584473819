@import "../../../styles/var";

.welcome {
}
.welcomeBody {
    padding: 260px 0 0 0;
    @media (max-width: $md2+px) {
        padding: 180px 0 0 0;
    }
    @media (max-width: $md3+px) {
        padding: 150px 0 0 0;
    }
    @media (max-width: $md4+px) {
        padding: 120px 0 0 0;
    }
    @media (max-width: $md6+px) {
        padding: 100px 0 0 0;
    }
}
.welcomeRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.welcomeRowLeft {
    flex: 0 1 525px;
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-bottom: 70px;
    }
}
.welcomeRowLeftTitle {
    margin-bottom: 30px;
    @media (max-width: $md3+px) {
        max-width: 600px;
    }
    @media (max-width: $md4+px) {
        max-width: 500px;
    }
    @media (max-width: $md6+px) {
        span {
            display: block;
        }
    }
}
.welcomeRowLeftText {
    margin-bottom: 50px;
    @media (max-width: $md3+px) {
        max-width: 686px;
    }
    @media (max-width: $md4+px) {
        max-width: 600px;
    }
}
.welcomeRowLeftBtn {
}
.welcomeRowRight {
    transform: translate(0px, 240px);
    opacity: 0;
    @media (max-width: $md3+px) {
        transform: translate(0px, 0px);
        opacity: 1;
    }
    img {
        max-width: 538px;
        width: 100%;
    }
}
