@import "../../../styles/var";

.crucial {
    background-color: #eaecf0;
}
.crucialBody {
    padding: 36px 0;
    background: url("../../../assets/img/Tools/2/1.png") right top / cover no-repeat;
    @media (max-width: 1350px) {
        background: url("../../../assets/img/Tools/2/1.png") 50% top / cover no-repeat;
    }
    @media (max-width: 1150px) {
        background: none;
    }
}
.crucialWrapper {
    max-width: 525px;
    @media (max-width: 1150px) {
        max-width: 683px;
    }
}
.crucialTitle {
    margin-bottom: 30px;
}
.crucialText {
}
