@import '../../../styles/var';

.docsWrap {
    position: relative;
    padding-top: 140px;
    @media (max-width: $md2+px) {
        padding-top: 130px;
    }
    @media (max-width: $md5+px) {
        padding-top: 110px;
    }
    .title {
        text-align: center;
    }
    .text {
        margin: 30px auto 60px;
        max-width: 550px;
        text-align: center;
        @media(max-width: $md6+px) {
            margin-bottom: 50px;
        }
    }
    .docs {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        @media(max-width: $md3+px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media(max-width: 700px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
        }
        .doc {
            display: grid;
            grid-template-columns: 41px 1fr;
            gap: 10px;
            align-items: center;
            color: #484848;
            font-family: $sans;
            font-size: 17px;
            font-weight: 500;
            line-height: 120%;
            @media(max-width: $md4+px) {
                font-size: 16px;
            }
            @media (max-width: $md6+px) {
            }
            &:hover {
                color: #000; 
                text-decoration: underline;
                svg path {
                    fill: #12b0bb;
                }
            }
            svg {
                width: 100%;
            }
        }
    }
}