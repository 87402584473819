@import '../../../styles/var';

.quickInfo {
    position: relative;
    padding-bottom: 160px;
    @media (max-width: $md1+px) {
        padding-bottom: 130px;
    }
    @media (max-width: $md4+px) {
        padding-bottom: 0;
    }
    .content {
        position: relative;
        z-index: 10;
        .title {

        }
        ul {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-top: 30px;
            max-width: 470px;
            li {
                position: relative;
                padding-left: 31px;
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 3px;
                    background-image: url('../../../assets/img/HomePage/QuickInfo/check.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    width: 20px;
                    height: 20px;
                    @media(max-width: $md4+px) {
                        top: 2px;
                    }
                    @media(max-width: $md6+px) {
                        width: 18px;
                        height: 18px;
                        top: 1px;
                    }
                }
            }
        }
    }
    .images {
        position: absolute;
        z-index: 1;
        right: 35px;
        bottom: 0;
        width: 536px;
        @media(max-width: $md3+px) {
            width: 400px;
        }
        @media(max-width: $md4+px) {
            position: relative;
            display: flex;
            margin: 60px auto 0 auto;
            bottom: unset;
            right: unset;
        }
        @media(max-width: 500px) {
            width: 300px;
            margin-top: 50px;
        }
        .man {
            position: relative;
            z-index: 1;
            width: 100%;
        }
        .icons {
            position: absolute;
            z-index: 2;
            width: 561px;
            right: -35px;
            top: 42px;
            @media(max-width: $md3+px) {
                width: 460px;
                top: 20px;
            }
            @media(max-width: 500px) {
                width: 320px;
                right: -10px;
            }
        }
    }
}