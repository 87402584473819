@import '../../../styles/var';

.howToConnect {
    position: relative;
    .title {

    }
    .content {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(2, 525px);
        justify-content: space-between;
        gap: 60px;
        @media(max-width: $md3+px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
        ul {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding-left: 30px;
            @media(max-width: $md6+px) {
                padding-left: 25px;
            }
            li {
                list-style-position: outside;
                list-style-type: disc;
            }
        }
        .text {

        }
        .btn {
            margin-top: 50px;
        }
    }
}