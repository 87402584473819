@import '../../../styles/var';

.efficient {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    @media(max-width: $md4+px) {
        flex-direction: column;
        align-items: unset;
        justify-content: unset;
        gap: 65px;
    }
    @media(max-width: $md6+px) {
        gap: 50px;
    }
    .content {
        position: relative;
        z-index: 10;
        .title {

        }
        .text {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-top: 30px;
            max-width: 525px;
            @media(max-width: $md4+px) {
                max-width: 700px;
            }
        }
    }
    .image {
        width: 604px;
        @media(max-width: $md3+px) {
            width: 400px;
        }
        @media(max-width: $md4+px) {
            width: 604px;
            display: flex;
            margin: 0 auto;
        }
        @media(max-width: $md5+px) {
            width: 450px;
        }
        @media(max-width: $md6+px) {
            width: 330px;
        }
    }
}