@import "../../../styles/var";

.tabs {
}
.tabsBody {
}
.tabsContent {
    display: flex;
    max-width: 689px;
    justify-content: space-between;
    margin: 0 auto;
    @media (max-width: 720px) {
        justify-content: center;
        flex-wrap: wrap;
    }
}
.tabsContentButtons {
    @media (max-width: 720px) {
        flex: 0 1 310px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}
.tabsContentBtn {
    border-radius: 30px;
    border: 1px solid #927f9c;
    width: 145px;
    padding: 10px 0;
    text-align: center;
    color: #300a44;
    font-family: $sans;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    transition: all 0.3s linear;
    cursor: pointer;
    &:last-child {
        margin-bottom: 0;
        @media (max-width: 720px) {
            margin-bottom: 10px;
        }
    }
    &.active {
        background-color: #b1e6e9;
        border-color: #b1e6e9;
    }
}
.btnText {
}
.tabsContentRight {
    flex: 0 1 465px;
}
.tabsContentTable {
    display: none;
    border-radius: 20px;
    border: 0px solid #2e2e2e;
    background: #fff;
    width: 100%;
    @media (max-width: 720px) {
        margin-top: 30px;
    }
    &.active {
        display: block;
    }
}
.tabsContentTableRow {
    display: flex;
    border-bottom: 1px solid #aaa;
    padding: 17px 10px 14px 10px;
    justify-content: center;
    &:last-child {
        border-bottom: none;
    }
    &:first-child {
        .tabsContentTableRowColumn {
            font-weight: 400 !important;
        }
    }
}
.tabsContentTableRowColumn {
    flex: 0 1 50%;
    text-align: left;
    color: #2e2e2e;
    font-family: $sans;
    font-size: 14px;
    font-weight: 300;
    @media (max-width: 500px) {
        &:nth-child(1) {
            flex: 1 1 auto;
        }
        &:nth-child(2) {
            flex: 0 0 200px;
        }
    }
    &.centerText {
        text-align: center;
    }
}
