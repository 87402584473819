@import '../../../styles/var';

.banner {
    position: relative;
    padding-top: 230px;
    @media(max-width: $md4+px) {
        padding-top: 180px;
    }
    @media(max-width: $md6+px) {
        padding-top: 140px;
    }
    .content {
        position: relative;
        z-index: 10;
        .title {
            max-width: 500px;
        }
        .text {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin: 30px 0 50px 0;
            max-width: 525px;
            @media(max-width: $md4+px) {
                max-width: 700px;
            }
        }
        .btn {

        }
    }
    .image {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 194px;
        width: 494px;
        @media(max-width: $md3+px) {
            width: 400px;
            top: 250px;
        }
        @media(max-width: $md4+px) {
            position: relative;
            top: unset;
            right: unset;
            display: flex;
            margin: 65px auto 0 auto;
            width: 386px;
        }
        @media(max-width: $md6+px) {
            margin-top: 50px;
            width: 260px;
        }
    }
}