@import "../../../styles/var";

.exceptional {
}

.exceptionalBody {
}
.exceptionalRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.exceptionalRowColumn1 {
    max-width: 525px;
    margin-bottom: 24px;
    @media (max-width: $md3+px) {
        max-width: 100%;
        margin-bottom: 65px;
    }
    @media (max-width: 740px) {
        margin-bottom: 30px;
    }
    @media (max-width: 740px) {
        order: 1;
    }
}
.exceptionalRowColumn1Title {
    margin-bottom: 30px;
    @media (max-width: $md3+px) {
        max-width: 600px;
    }
    @media (max-width: $md6+px) {
        max-width: 340px;
    }
}
.exceptionalRowColumn1Text {
    @media (max-width: $md3+px) {
        max-width: 600px;
    }
    font-weight: 300 !important;
}

.exceptionalRowColumn2 {
    flex: 0 0 370px;
    margin-left: 55px;
    padding: 40px 25px 70px 25px;
    position: relative;
    margin-bottom: 24px;
    @media (max-width: $md3+px) {
        flex: 0 1 49%;
        margin-left: 0;
    }
    @media (max-width: 870px) {
        padding: 20px 15px 40px 15px;
    }
    @media (max-width: 740px) {
        order: 3;
        flex: 0 0 100%;
        padding: 0;
    }
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #13c5d0;
        @media (max-width: 740px) {
            background-color: transparent;
        }
    }
    &:before {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: url("../../../assets/img/Academy/2/1.png") right top / contain no-repeat;
        @media (max-width: 740px) {
            background: none;
        }
    }
}
.exceptionalRowColumn2Title {
    margin-bottom: 35px;
    color: #222b40 !important;
    position: relative;
    z-index: 3;
    @media (max-width: 870px) {
        margin-bottom: 15px;
    }
}
.exceptionalRowColumn2Text {
    color: #222b40 !important;
    font-weight: 300 !important;
    position: relative;
    z-index: 3;
}
.exceptionalRowColumn3 {
    flex: 0 1 865px;
    background-color: #dedede;
    padding: 40px 25px 70px 25px;

    @media (max-width: 740px) {
        background-color: transparent;
        margin-bottom: 30px;
    }
    position: relative;
    @media (max-width: $md2+px) {
        flex: 0 1 775px;
    }
    @media (max-width: $md3+px) {
        order: 5;
        flex: 0 1 100%;
    }
    @media (max-width: 870px) {
        padding: 20px 15px 40px 15px;
    }
    @media (max-width: 740px) {
        order: 2;
        padding: 0;
    }
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #dedede;
        @media (max-width: 740px) {
            background-color: transparent;
        }
    }
    &:before {
        content: "";
        display: inline-block;
        right: 0;
        bottom: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: url("../../../assets/img/Academy/2/2.png") right bottom / contain no-repeat;
        @media (max-width: 740px) {
            display: none;
        }
        @media (max-width: $md2+px) {
            width: 70%;
            height: 70%;
        }
        @media (max-width: $md3+px) {
            width: 100%;
            height: 100%;
        }
        @media (max-width: 870px) {
            width: 70%;
            height: 70%;
        }
    }
}
.exceptionalRowColumn3Title {
    color: #222b40 !important;
    margin-bottom: 35px;
    position: relative;
    z-index: 2;
    @media (max-width: 870px) {
        margin-bottom: 15px;
    }
}
.exceptionalRowColumn3Text {
    max-width: 525px;
    color: #222b40 !important;
    font-weight: 300 !important;
    position: relative;
    z-index: 2;
}
.exceptionalRowColumn4 {
    flex: 0 0 370px;
    margin-left: 55px;
    padding: 40px 25px 70px 25px;
    background-color: #300a44;
    @media (max-width: 740px) {
        background-color: transparent;
        padding: 0 !important;
        margin: 0 !important;
        flex: 0 0 100% !important;
        width: 100%;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 49%;
        margin-left: 0;
        margin-bottom: 24px;
    }
    @media (max-width: 870px) {
        padding: 20px 15px 40px 15px;
    }
    @media (max-width: 740px) {
        order: 4;
    }
}
.exceptionalRowColumn4Title {
    margin-bottom: 35px;
    color: #fff !important;
    @media (max-width: 870px) {
        margin-bottom: 15px;
    }
    @media (max-width: 740px) {
        color: #222b40 !important;
    }
}
.exceptionalRowColumn4Text {
    color: #fff !important;
    font-weight: 300 !important;
    @media (max-width: 740px) {
        color: #222b40 !important;
    }
}
