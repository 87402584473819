@import '../../../styles/var';

.exBenefitsWrap {
    position: relative;
    background: #13C5D0;
}

.exBenefits {
    position: relative;
    padding: 90px 0 150px 0;
    @media(max-width: $md3+px) {
        padding: 65px 0 0 0;
        display: flex;
        flex-direction: column;
        gap: 65px;
    }
    @media(max-width: $md6+px) {
        padding-top: 50px;
        gap: 50px;
    }
    .circle {
        position: absolute;
        z-index: 1;
        right: -330px;
        top: 0;
        width: 1007px;
        @media(max-width: $md3+px) {
            display: none;
        }
    }
    .block {
        .title {
            position: relative;
            z-index: 10;
        }
        &_01 {
            position: relative;
            display: flex;
            justify-content: space-between;
            @media(max-width: $md3+px) {
                flex-direction: column;
                justify-content: unset;
                gap: 50px;
            }
            @media(max-width: $md6+px) {
                gap: 30px;
            }
            .cardWrap {
                &:nth-child(1) {
                    transform: translateY(100px);
                    @media(max-width: $md3+px) {
                        transform: unset;
                    }
                }
                &:nth-child(2) {
                    transform: translateY(150px);
                    @media(max-width: $md3+px) {
                        transform: unset;
                    }
                }
            }
        }
        &_02 {
            margin-top: 220px;
            @media(max-width: $md3+px) {
                margin-top: 0;
            }
            .cardWrap {
                &:nth-child(1), &:nth-child(3) {
                    .card {
                        transform: translateY(0)!important;
                    }
                }
                &:nth-child(2), &:nth-child(4) {
                    .card {
                        transform: translateY(40px)!important;
                    }
                }
            }
        }
        .cards {
            position: relative;
            display: grid;
            grid-template-columns: repeat(2, 277px);
            grid-template-rows: repeat(2, auto);
            gap: 50px 40px;
            @media(max-width: $md3+px) {
                display: flex;
                flex-direction: column;
                gap: 30px;
            }
            @media(max-width: $md6+px) {
                gap: 15px;
            }
            .cardWrap {
                height: 100%;
                &:nth-child(1), &:nth-child(3) {
                    .card {
                        transform: translateY(40px);
                    }
                }
                .card {
                    position: relative;
                    z-index: 10;
                    height: 100%;
                    border-radius: 20px;
                    background: #FFF;
                    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 4px 10px 0px rgba(0, 0, 0, 0.10), 0px 18px 18px 0px rgba(0, 0, 0, 0.09), 0px 40px 24px 0px rgba(0, 0, 0, 0.05), 0px 72px 29px 0px rgba(0, 0, 0, 0.01), 0px 112px 31px 0px rgba(0, 0, 0, 0.00);
                    padding: 30px 17px;
                    @media(max-width: $md3+px) {
                        transform: none!important;
                        box-shadow: none;
                        padding: 0;
                        border-radius: 0;
                        background: transparent;
                    }   
                    &Title {
                        color: #300A44;
                        font-family: $sans;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        @media(max-width: $md3+px) {
                            color: #222B40;
                            font-weight: 600;
                        }   
                        @media(max-width: $md4+px) {
                            font-size: 16px;
                            line-height: 150%;
                        }
                        @media(max-width: $md6+px) {
                            font-size: 14px;
                        }
                    }
                    &Text {
                        color: #484848;
                        font-family: $sans;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 150%;
                        margin-top: 20px;
                        @media(max-width: $md3+px) {
                            color: #222B40;
                            max-width: 700px;
                            font-size: 16px;
                        }
                        @media(max-width: $md4+px) {
                            margin-top: 15px;
                        }
                        @media(max-width: $md6+px) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .btn {
            display: none;
            @media(max-width: $md3+px) {
                display: block;
            }
            @media(max-width: $md6+px) {
                margin-top: 20px;
            }
        }
        .image_01 {
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            width: 553px;
            @media(max-width: $md2+px) {
                width: 500px;
            }
            @media(max-width: $md3+px) {
                position: relative;
                display: flex;
                bottom: unset;
                left: unset;
                margin: 0 auto 0 auto;
                transform: translateX(30px);
            }
            @media(max-width: 520px) {
                width: 307px;
                transform: translateX(20px);
            }
            @media(max-width: $md6+px) {
                margin-top: 20px;
            }
        }
        .image_02 {
            position: absolute;
            z-index: 1;
            right: 0;
            bottom: 0;
            width: 443px;
            @media(max-width: $md2+px) {
                width: 400px;
            }
            @media(max-width: $md3+px) {
                position: relative;
                display: flex;
                bottom: unset;
                right: unset;
                margin: 0 auto 0 auto;
                margin-top: 65px;
                width: 293px;
            }
            @media(max-width: 520px) {
                width: 183px;
            }
            @media(max-width: $md6+px) {
                margin-top: 50px;
            }
        }
    }
}