@import '../../../styles/var';

.tradingAcademy {
    position: relative;
    .title {
        max-width: 576px;
        margin: 0 auto;
        text-align: center;
    }
    .text {
        max-width: 525px;
        text-align: center;
        margin: 30px auto 75px auto;
        @media(max-width: $md5+px) {
            margin-bottom: 65px;
        }
        @media(max-width: $md6+px) {
            margin-bottom: 30px;
        }
    }
    .cards {
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 360px 292px;
        gap: 55px;
        @media(max-width: $md2+px) {
            gap: 40px;
        }
        @media(max-width: $md3+px) {
            gap: 20px;
        }
        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }
        @media(max-width: $md6+px) {
            gap: 15px;
        }
        .card {
            position: relative;
            padding: 40px 25px;
            @media(max-width: $md3+px) {
                padding: 30px 20px;
            }
            @media(max-width: $md4+px) {
                padding: 0;
                background: transparent!important;
                &::before {
                    display: none!important;
                }
                .btn {
                    display: none!important;
                }
                .planeWrap {
                    display: none!important;
                }
            }
            &:nth-child(1) {
                background: #13C5D0;
                color: #300A44;
                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: 1;
                grid-row-end: 1;
                .planeWrap {
                    position: absolute;
                    z-index: 1;
                    bottom: -88px;
                    right: -58px;
                    @media(max-width: $md3+px) {
                        bottom: -60px;
                        right: -40px;
                    }
                    .plane {
                        background-image: url('../../../assets/img/HomePage/TradingAcademy/plane.png');
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center center;
                        width: 308px;
                        height: 258px;
                        @media(max-width: $md3+px) {
                            width: calc(308px * 0.8);
                            height: calc(258px * 0.8);
                        }
                    }
                }
            }
            &:nth-child(2) {
                background: #300A44;
                color: #FFF;
                grid-column-start: 2;
                grid-column-end: 2;
                grid-row-start: 1;
                grid-row-end: 1;
                &::before {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-image: url('../../../assets/img/HomePage/TradingAcademy/bg.png');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                }
            }
            &:nth-child(3) {
                background: #13C5D0;
                color: #300A44;
                grid-column-start: 3;
                grid-column-end: 3;
                grid-row-start: 2;
                grid-row-end: 2;
            }
            &:nth-child(4) {
                background: #DEDEDE;
                color: #222B40;
                grid-column-start: 3;
                grid-column-end: 1;
                grid-row-start: 2;
                grid-row-end: 2;
                .cardText {
                    max-width: 550px;
                }
            }
            &:nth-child(5) {
                background: #FFF;
                color: #300A44;
                grid-column-start: 3;
                grid-column-end: 3;
                grid-row-start: 1;
                grid-row-end: 1;
            }
            &Title {
                position: relative;
                z-index: 10;
                font-family: $sans;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @media(max-width: $md4+px) {
                    color: #222B40!important;
                    font-size: 16px;
                    font-weight: 600;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                }
            }
            &Text {
                position: relative;
                z-index: 10;
                font-family: $sans;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 150%;
                margin-top: 35px;
                max-width: 320px;
                @media(max-width: $md4+px) {
                    color: #222B40!important;
                    margin-top: 15px;
                    max-width: 700px!important;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                }
            }
            .btn {
                position: absolute;
                z-index: 10;
                bottom: 40px;
                right: 40px;
                width: 61px;
                height: 45px;
                background-image: url('../../../assets/img/HomePage/TradingAcademy/button.png');
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;
                transition: all .3s;
                &:hover {
                    transform: scale(0.95);
                }
            }
        }
    }
}