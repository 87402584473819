@import "../../../styles/var";

.footer {
    position: relative;
    z-index: 10;
    font-family: $sans;
    font-weight: 300;
    font-size: 14px;
    line-height: 140%;
    padding-bottom: 20px;
    b {
        font-weight: 600;
    }
    .logo {
        width: 160px;
        @media(max-width: $md6+px) {
            width: 140px;
        }
        img {
            width: 100%;
        }
    }
    .mission {
        margin: 20px 0;
    }
    .block {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .docs {
        font-weight: 600;
        color: inherit;
        text-decoration: none;
        &:hover {
            color: #000;
            text-decoration: underline;
        }
    }
    .bottom {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 150px;
        justify-content: space-between;
        @media(max-width: $md2+px) {
            gap: 100px;
        }
        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
        .rights {
            font-weight: 600;
        }
        .rsk {
            font-size: 10px;
            line-height: 140%;
        }
    }
}
