@import "../../../styles/var";

.banner {
}
.bannerBody {
    padding: 140px 0 0 0;
    position: relative;
    @media (max-width: $md2+px) {
        padding: 130px 0 0 0;
    }
    @media (max-width: $md5+px) {
        padding: 110px 0 0 0;
    }
}
.bannerRow {
    display: flex;
    justify-content: space-between;
    position: relative;

    z-index: 3;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.bannerRowLeft {
    flex: 0 1 525px;
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
    }
}
.bannerImg {
    position: absolute;
    right: 0;
    top: 80px;
    width: 675px;
    height: 834px;
    @media (max-width: $md3+px) {
        top: 0;
    }
    @media (max-width: $md5+px) {
        display: none;
    }
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.bannerRowLeftTitle {
    margin-bottom: 30px;
}
.bannerRowLeftText {
    margin-bottom: 15px;
}
.bannerRowLeftBtn {
    margin-top: 50px;
}
.bannerRowRight {
    flex: 0 1 610px;
    position: relative;
    top: 50px;
    transform: translate(0px, 200px);
    opacity: 0;

    @media (max-width: $md2+px) {
        top: 15px;
    }
    @media (max-width: $md3+px) {
        margin-top: 50px;
        top: 0;
        transform: translate(0px, 0);
        opacity: 1;
    }

    img {
        width: 100%;
    }
}
