@import '../../../styles/var';

.toolsWrap {
    position: relative;
    z-index: 10;
    margin-top: -1px;
    background: #13C5D0;
    .circle {
        position: absolute;
        top: 0;
        right: -170px;
        height: 100%;
        @media(max-width: $md3+px) {
            display: none;
        }
    }
}

.tools {
    position: relative;
    display: grid;
    grid-template-columns: 525px 594px;
    justify-content: space-between;
    align-items: flex-end;
    gap: 60px;
    padding: 90px 0 125px 0;
    @media(max-width: $md3+px) {
        padding: 65px 0;
        display: flex;
        flex-direction: column;
        align-items: unset;
        justify-content: unset;
        gap: 30px;
    }
    @media(max-width: $md6+px) {
        padding: 50px 0;
    }
    .content {
        .title {

        }
        .text {
            color: #300A44;
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin: 30px 0 50px 0;
            max-width: 525px;
            @media(max-width: $md3+px) {
                margin-bottom: 0;
                max-width: 700px;
            }
        }
        .btn {
            @media(max-width: $md3+px) {
                display: none;
            }
        }
    }
    .cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px 40px;
        @media(max-width: $md3+px) {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }
        @media(max-width: $md6+px) {
            gap: 15px;
        }
        .cardWrap {
            &:nth-child(1), &:nth-child(3) {
                .card {
                    transform: translateY(60px);
                }
            }
            .card {
                border-radius: 20px;
                background: #FFF;
                box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 4px 10px 0px rgba(0, 0, 0, 0.10), 0px 18px 18px 0px rgba(0, 0, 0, 0.09), 0px 40px 24px 0px rgba(0, 0, 0, 0.05), 0px 72px 29px 0px rgba(0, 0, 0, 0.01), 0px 112px 31px 0px rgba(0, 0, 0, 0.00);
                padding: 30px 17px;
                @media(max-width: $md3+px) {
                    transform: none!important;
                    box-shadow: none;
                    padding: 0;
                    border-radius: 0;
                    background: transparent;
                }   
                &Title {
                    color: #300A44;
                    font-family: $sans;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    @media(max-width: $md3+px) {
                        color: #222B40;
                        font-weight: 600;
                    }   
                    @media(max-width: $md4+px) {
                        font-size: 16px;
                        line-height: 150%;
                    }
                    @media(max-width: $md6+px) {
                        font-size: 14px;
                    }
                }
                &Text {
                    color: #484848;
                    font-family: $sans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%;
                    margin-top: 20px;
                    @media(max-width: $md3+px) {
                        color: #222B40;
                        max-width: 700px;
                    }
                    @media(max-width: $md4+px) {
                        margin-top: 15px;
                    }
                    @media(max-width: $md6+px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .btnMob {
        display: none;
        @media(max-width: $md3+px) {
            display: block;
            margin-top: 20px;
        }
    }
}