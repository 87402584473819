@import '../../../styles/var';

.whatTrade {
    position: relative;
    .circle {
        position: absolute;
        z-index: 1;
        top: -240px;
        left: -400px;
        width: 1030px;
        @media(max-width: $md3+px) {
            display: none;
        }
    }
    .title {
        position: relative;
        z-index: 10;
        text-align: center;
    }
    .container {
        position: relative;
        z-index: 10;
        display: grid;
        grid-template-columns: auto 525px;
        gap: 80px;
        margin-top: 75px;
        @media(max-width: $md4+px) {
            margin-top: 65px;
            display: flex;
            flex-direction: column;
            gap: 65px;
        }
        @media(max-width: $md6+px) {
            margin-top: 50px;
            gap: 50px;
        }
        .buttons {
            display: flex;
            flex-direction: column;
            gap: 20px;
            @media(max-width: $md4+px) {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(2, 50px);
                gap: 20px 30px;
            }
            @media(max-width: $md5+px) {
                gap: 20px;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(3, 45px);
            }
            .button {
                position: relative;
                cursor: pointer;
                color: #300A44;
                font-family: $sans;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                transition: all .1s;
                display: flex;
                @media(max-width: $md4+px) {
                    padding-left: 0!important;
                    font-size: 16px;
                    border-radius: 30px;
                    border: 1px solid rgba(48, 10, 68, 0.50);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    font-weight: 400;
                    padding: 0 5px;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: calc(50%);
                    height: 1px;
                    width: 0;
                    opacity: 0;
                    background: #300A44;
                    transition: all .1s;
                    @media(max-width: $md4+px) {
                        display: none!important;
                    }
                }
                &Active {
                    font-weight: 400;
                    padding-left: 57px;
                    @media(max-width: $md4+px) {
                        background: rgba(19, 197, 208, 0.30);
                        border-color: rgba(19, 197, 208, 0.30);
                    }
                    &::before {
                        opacity: 1;
                        width: 45px;
                    }
                }
            }
        }
        .content {
            .text {
                display: flex;
                flex-direction: column;
                gap: 15px;
                b {
                    font-weight: 400;
                }
            }
            ul {
                display: flex;
                flex-direction: column;
                gap: 5px;
                padding-left: 25px;
                margin-top: 15px;
                li {
                    list-style-type: disc;
                    list-style-position: outside;
                }
            }
            .btn {
                margin-top: 50px;
            }
        }
    }
}