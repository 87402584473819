@import "../../../styles/var";

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

@include keyframes(top-anim) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, 13px);
    }
}
@include keyframes(top-anim1) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, 15px);
    }
}
@include keyframes(top-anim2) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, -15px);
    }
}
.block {
}
.blockBody {
}
.blockRow {
    display: flex;
    justify-content: space-between;
}
.blockRowLeft {
    flex: 0 1 525px;
}
.blockRowLeftTitle {
    margin-bottom: 30px;
}
.blockRowLeftText {
    margin-bottom: 15px;
}
.blockRowLeftBtn {
    margin-top: 50px;
}
.blockRowRight {
    position: relative;
}
.blockRowRightImgs {
    width: 432px;
    height: 438px;
    position: absolute;
    right: 0;
    top: 0;
    @media (max-width: 1100px) {
        display: none;
    }
}
.blockRowRightImgsImg1 {
    position: absolute;
    right: 70px;
    top: 70px;
    width: 252px;
    height: 368px;

    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.blockRowRightImgsImg2 {
    position: absolute;
    left: 0;
    top: 0;
    width: 112px;
    height: 118px;
    @include animation("top-anim linear 2.7s alternate infinite");
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.blockRowRightImgsImg3 {
    position: absolute;
    left: -40px;
    top: 150px;

    width: 60px;
    height: 60px;
    @include animation("top-anim2 linear 2.7s alternate infinite");
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.blockRowRightImgsImg4 {
    position: absolute;
    right: 30px;
    top: 220px;

    width: 72px;
    height: 78px;
    @include animation("top-anim1 linear 1.7s alternate infinite");
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}

.imgMob {
    display: none;
    @media (max-width: 1100px) {
        display: block;
        text-align: center;
        img {
            max-width: 100%;
        }
        margin-top: 60px;
    }
}
