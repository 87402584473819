@import "../../../styles/var";

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

@include keyframes(top-anim) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, 20px);
    }
}
@include keyframes(top-anim1) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, -20px);
    }
}
@include keyframes(top-anim2) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, 15px);
    }
}

.banner {
}
.bannerBody {
    padding: 240px 0 0 0;
    position: relative;
    @media (max-width: $md2+px) {
        padding: 180px 0 0 0;
    }
    @media (max-width: $md3+px) {
        padding: 112px 0 0 0;
    }
    @media (max-width: $md6+px) {
        padding: 100px 0 0 0;
    }
}
.bannerBg {
    position: absolute;
    right: 0;
    top: 80px;
    width: 675px;
    height: 834px;
    @media (max-width: $md3+px) {
        top: 0;
    }
    @media (max-width: $md6+px) {
        display: none;
    }
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.bannerRow {
    display: flex;
    justify-content: space-between;
}
.bannerRowLeft {
    flex: 0 1 525px;
    @media (max-width: $md3+px) {
        flex: 0 1 683px;
    }
}
.bannerRowLeftTitle {
    margin-bottom: 26px;
}
.bannerRowLeftText {
    margin-bottom: 15px;
}
.bannerRowLeftBtn {
    margin-top: 50px;
}
.bannerRowRight {
    position: relative;
    flex: 0 1 600px;
    @media (max-width: $md3+px) {
        display: none;
    }
}
.bannerRowRightImgs {
    position: absolute;
    right: 0;
    top: 0;
    height: 581px;
    width: 337px;
    pointer-events: none;
    @media (max-width: $md3+px) {
        display: none;
    }
}
.bannerRowRightImgsImg1 {
    position: absolute;
    left: 65px;
    top: -45px;
    width: 70px;
    height: 70px;
    transform: translate(100px, -20px) rotate(40deg);
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        @include animation("top-anim linear 2.4s alternate infinite");
    }
}
.bannerRowRightImgsImg2 {
    position: absolute;
    left: -220px;
    top: 170px;
    width: 88px;
    height: 87px;
    transform: translate(50px, 40px) rotate(-30deg);
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        @include animation("top-anim1 linear 2.5s alternate infinite");
    }
}
.bannerRowRightImgsImg3 {
    position: absolute;
    right: 190px;
    top: -50px;
    width: 360.318px;
    height: 323.01px;
    transform: translate(-150px, 30px) rotate(0deg);
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.bannerRowRightImgsImg4 {
    position: absolute;
    right: 70px;
    top: 70px;
    width: 125.107px;
    height: 130.646px;
    transform: translate(50px, 40px) rotate(-50deg);
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        @include animation("top-anim linear 2.8s alternate infinite");
    }
}
.bannerRowRightImgsImg5 {
    position: absolute;
    left: -90px;
    top: 160px;
    width: 63.98px;
    height: 68.185px;
    transform: translate(50px, 40px) rotate(-30deg);
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        @include animation("top-anim linear 3s alternate infinite");
    }
}
.bannerRowRightImgsImg6 {
    position: absolute;
    right: 215px;
    top: 110px;
    width: 50px;
    height: 50px;
    transform: translate(50px, 40px) rotate(-30deg);
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        @include animation("top-anim linear 3.2s alternate infinite");
    }
}

.bannerMob1 {
    text-align: center;
    position: relative;
    z-index: 2;
    display: none;
    @media (max-width: $md3+px) {
        display: block;
    }
    @media (max-width: $md6+px) {
        display: none;
    }
    margin-top: 50px;
    img {
        max-width: 100%;
    }
}
.bannerMob2 {
    text-align: center;
    display: none;
    position: relative;
    z-index: 2;
    @media (max-width: $md6+px) {
        display: block;
    }
    margin-top: 30px;
    img {
        max-width: 100%;
    }
}
