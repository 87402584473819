@import "./_var.scss";

body,
html {
    background-color: #F4F4F4;
    color: #484848;
    &.lock {
        overflow: hidden;
    }
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.font-35 {
    color: #300A44;
    font-family: $sans;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    @media (max-width: $md4+px) {
        font-size: 30px;
    }
    @media (max-width: $md6+px) {
        font-size: 18px;
    }
}

.font-20 {
    color: #300A44;
    font-family: $sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media(max-width: $md4+px) {
        font-size: 16px;
    }
    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-17 {
    color: #484848;
    font-family: $sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    @media(max-width: $md4+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}

.mt {
    margin-top: 160px;
    @media (max-width: $md4+px) {
        margin-top: 130px;
    }
    @media (max-width: $md6+px) {
        margin-top: 90px;
    }
}
.pt {
    padding-top: 160px;
    @media (max-width: $md4+px) {
        padding-top: 130px;
    }
    @media (max-width: $md6+px) {
        padding-top: 90px;
    }
}
.ovf-hidden {
    overflow: hidden;
}

.Dropdown-control {
    font-family: $sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    background-color: transparent;
    border: none;
    color: #2a2b2b;
    text-transform: uppercase;
    padding-right: 30px;
    cursor: pointer;
    &:hover {
        box-shadow: none;
    }
}
.Dropdown-menu {
    font-family: $sans;
    font-style: normal;
    font-size: 14px;
}
.Dropdown-arrow {
    border-color: #2a2b2b transparent transparent;
}
.is-open .Dropdown-arrow {
    border-color: transparent transparent #2a2b2b;
}
