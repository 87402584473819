@import "../../../styles/var";

.market {
}
.marketBody {
}
.marketRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}
.marketRowLeft {
    flex: 0 1 553px;
    img {
        width: 100%;
    }
    @media (max-width: $md3+px) {
        order: 2;
        flex: 0 1 100%;
        margin-top: 50px;
        text-align: center;
        img {
            max-width: 553px;
        }
    }
}
.marketRowRight {
    flex: 0 1 525px;
    @media (max-width: $md4+px) {
        flex: 0 1 425px;
    }
}
.marketRowRightTitle {
    margin-bottom: 30px;
    @media (max-width: $md6+px) {
        max-width: 240px;
    }
}
.marketRowRightList {
}
.marketRowRightListItem {
    position: relative;
    padding-left: 25px;
    margin-bottom: 19px;
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 3px;
        position: absolute;
        width: 18px;
        height: 19px;
        border: 1px solid #222b40;
    }
}
.marketRowRightText {
    margin-top: 30px;
}
.marketRowRightBtn {
    margin-top: 50px;
}
