@import "../../../styles/var";

.comprehensive {
}
.comprehensiveBody {
}
.comprehensiveTitle {
    text-align: center;
    max-width: 525px;
    margin: 0 auto 75px auto;
    @media (max-width: $md4+px) {
        max-width: 425px;
        margin: 0 auto 50px auto;
    }
    @media (max-width: $md6+px) {
        max-width: 265px;
    }
}
.comprehensiveRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1080px) {
        display: none;
    }
}
.comprehensiveRowLeft {
    flex: 0 1 400px;
}
.comprehensiveRowLeftBtn {
    font-weight: 300 !important;
    margin-bottom: 20px;
    position: relative;
    transition: all 0.1s linear;
    padding-left: 0;
    overflow: hidden;
    cursor: pointer;
    &:after {
        content: "";
        display: inline-block;
        left: -100px;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: all 0.1s linear;
        position: absolute;
        width: 45px;
        height: 1px;
        background-color: #300a44;
    }
    &:last-child {
        margin-bottom: 0;
    }
    &.active {
        font-weight: 400 !important;
        padding-left: 55px;
        &:after {
            left: 0;
        }
    }
}
.comprehensiveRowRight {
    flex: 0 1 525px;
}
.comprehensiveRowRightText {
    display: none;
    &.active {
        display: block;
    }
}
.comprehensiveRowRightBtn {
    margin-top: 50px;
}

.comprehensiveMob {
    display: none;
    @media (max-width: 1080px) {
        display: block;
    }
}
.comprehensiveMobItem {
    margin-bottom: 30px;
}
.comprehensiveMobItemTitle {
    margin-bottom: 15px;
    color: #222b40 !important;
    font-weight: 600 !important;
}
.comprehensiveMobItemText {
}
.comprehensiveMobBtn {
    margin-top: 50px;
}
