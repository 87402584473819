@import '../../../styles/var';

.becomeTrader {
    position: relative;
    .title {
        max-width: 494px;
        margin: 0 auto;
        text-align: center;
        @media(max-width: $md4+px) {
            max-width: 440px;
        }
        @media(max-width: $md6+px) {
            max-width: 280px;
        }
    }
    .steps {
        position: relative;
        margin: 75px auto 0 auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        @media(max-width: $md4+px) {
            margin-top: 65px;
            display: flex;
            flex-direction: column;
            gap: 65px;
        }
        @media(max-width: $md6+px) {
            margin-top: 50px;
            gap: 50px;
        }
        .step {
            position: relative;
            z-index: 10;
            text-align: center;
            &:nth-child(1) {
                .stepNum {
                    border-width: 3px;
                }
            }
            &:nth-child(2) {
                .stepNum {
                    border-width: 5px;
                }   
            }
            &:nth-child(3) {
                .stepNum {
                    border-width: 8px;
                }
            }
            &:hover {
                .stepNum {
                    -webkit-box-shadow: 0px 0px 0px 50px #F4F4F4;
                    -moz-box-shadow: 0px 0px 0px 50px #F4F4F4;
                    box-shadow: 0px 0px 0px 50px #F4F4F4;
                }
            }
            &Num {
                position: relative;
                z-index: 11;
                border: 3px solid #13C5D0;
                color: #300A44;
                text-align: center;
                font-family: $sans;
                font-size: 60px;
                font-weight: 800;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 100px;
                border-radius: 100%;
                margin: 0 auto;
                background: #F4F4F4;
                -webkit-box-shadow: 0px 0px 0px 20px #F4F4F4;
                -moz-box-shadow: 0px 0px 0px 20px #F4F4F4;
                box-shadow: 0px 0px 0px 20px #F4F4F4;
                transition: all .2s;
                @media(max-width: $md4+px) {
                    -webkit-box-shadow: none!important;
                    -moz-box-shadow: none!important;
                    box-shadow: none!important;
                }
                @media(max-width: $md6+px) {
                    width: 85px;
                    height: 85px;
                    font-size: 45px;
                }
            }
            &Title {
                position: relative;
                z-index: 12;
                color: #300A44;
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 25px 0 15px 0;
                @media(max-width: $md4+px) {
                    font-size: 16px;
                    margin-bottom: 10px;
                }
            }
            &Text {
                position: relative;
                z-index: 12;
                @media(max-width: $md4+px) {
                    font-size: 16px!important;
                }
            }
        }
        .line {
            position: absolute;
            z-index: 1;
            width: 60%;
            height: 3px;
            left: 0;
            right: 0;
            margin: 0 auto;
            top: calc(50px - 1.5px);
            background: #13C5D0;
            @media(max-width: $md4+px) {
                display: none;
            }
        }
    }
}