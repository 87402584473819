@import "../../../styles/var";

.bonus {
}
.bonusBody {
}
.bonusTitle {
    margin-bottom: 30px;
    max-width: 450px;
    @media (max-width: $md4+px) {
        max-width: 350px;
    }
    @media (max-width: $md6+px) {
        max-width: 200px;
    }
}
.bonusRow {
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}
.bonusRowLeft {
    flex: 0 1 525px;
}
.bonusRowLeftText {
}
.bonusRowRight {
    flex: 0 1 525px;
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
    }
}
.bonusRowRightText {
    @media (max-width: $md3+px) {
        max-width: 525px;
        margin-top: 20px;
    }
}
.bonusBtn {
}
