@import "../../../styles/var";

.support {
}
.supportBody {
}
.supportRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}
.supportRowLeft {
    flex: 0 1 463px;
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-top: 30px;
        order: 2;
        text-align: center;
        margin-top: 50px;
    }
    img {
        width: 100%;
        @media (max-width: $md3+px) {
            max-width: 463px;
        }
    }
}
.supportRowRight {
    flex: 0 1 525px;
    @media (max-width: $md3+px) {
        order: 1;
    }
}
.supportRowRightTitle {
    margin-bottom: 30px;
}
.supportRowRightText {
}
