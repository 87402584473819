@import "../../../styles/var";

.practice {
}
.practiceBody {
}
.practiceRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}
.practiceRowLeft {
    flex: 0 1 525px;
}
.practiceRowLeftTitle {
    max-width: 400px;
    margin-bottom: 40px;
    @media (max-width: $md6+px) {
        margin-bottom: 30px;
    }
}
.practiceRowLeftText {
}
.practiceRowRight {
    flex: 0 1 525px;
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        text-align: center;
        margin-top: 50px;
    }
    img {
        width: 100%;
        @media (max-width: $md3+px) {
            max-width: 525px;
        }
    }
}
