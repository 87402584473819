@import '../../../styles/var';

.banner {
    position: relative;
    z-index: 9;
    padding: 150px 0 86px 0;
    height: 100vh;
    display: flex;
    align-items: center;
    min-height: 750px;
    @media(max-width: $md3+px) {
        min-height: unset;
        height: unset;
        align-items: unset;
        display: block;
    }
    @media(max-width: $md4+px) {
        padding: 180px 0 0 0;
    }
    @media(max-width: $md6+px) {
        padding-top: 140px;
    }
    .content {
        position: relative;
        z-index: 10;
        // width: 576px;
        width: 800px;
        margin-left: auto;
        margin-right: 0;
        @media(max-width: $md2+px) {
            margin-right: 50px;
            width: 760px;
        }
        @media(max-width: $md3+px) {
            margin-right: 0;
            width: calc(100% - 350px)
        }
        @media(max-width: $md4+px) {
            margin-left: 0;
            max-width: 576px;
            width: 100%;
        }
        .title {

        }
        .text {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin: 30px 0 50px 0;
            // max-width: 495px;
            @media(max-width: $md4+px) {
                max-width: 700px;
            }
        }
        .btn {

        }
    }
    .images {
        position: absolute;
        z-index: 1;
        height: 100%;
        top: 56px;
        left: 0;
        width: 376px;
        @media(max-width: $md2+px) {
            transform: scale(0.9);
            left: -60px;
        }
        @media(max-width: $md3+px) {
            transform: scale(0.8);
            left: -80px;
        }
        @media(max-width: $md4+px) {
            left: unset;
            top: unset;
            position: relative;
            width: calc(100% + 82px);
            transform: translateX(-41px);
            height: 184px;
            margin-top: 70px;
        }
        @media(max-width: $md6+px) {
            width: calc(100% + 38px);
            transform: translateX(-19px);
            height: 165px;
            margin-top: 50px;
        }
        .lineWrap_01 {
            position: absolute;
            z-index: 1;
            width: 2px;
            height: 529px;
            left: 119px;
            top: 230px;
            @media(max-width: $md4+px) {
                height: 1px;
                width: 305px;
                left: -270px;
                right: 0;
                margin: 0 auto;
                top: 31px;
            }
            @media(max-width: $md6+px) {
                top: 49px;
                left: -300px;
            }
        }
        .lineWrap_02 {
            position: absolute;
            z-index: 1;
            width: 35px;
            height: 696px;
            top: 119px;
            left: 163px;
            @media(max-width: $md4+px) {
                height: 20px;
                width: 100%;
                left: 0;
                top: 56px;
            }
            @media(max-width: $md6+px) {
                height: 9px;
                top: 71px;
            }
        }
        .lineWrap_03 {
            position: absolute;
            z-index: 1;
            width: 2px;
            height: 529px;
            top: 0;
            right: 15px;
            @media(max-width: $md4+px) {
                height: 2px;
                width: 396px;
                top: unset;
                left: 0;
                right: -340px;
                margin: 0 auto;
                bottom: 13px;
            }
            @media(max-width: $md6+px) {
                bottom: 10px;
            }
        }
        .line {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #13C5D0;
        }
        .planeWrap {
            position: absolute;
            z-index: 5;
            top: 163px;
            right: 0;
            width: 279px;
            @media(max-width: $md4+px) {
                left: 0;
                right: 0;
                bottom: -63px;
                margin: 0 auto;
                width: 161px;
                top: unset;
                transform: rotate(90deg);
            }
            @media(max-width: $md6+px) {
                width: 120px;
                bottom: -47px;
                right: -65px;
            }
            .plane {
                width: 100%;
            }
        }
        .iconWrap {
            position: absolute;
            z-index: 3;
            &_01 {
                width: 97px;
                top: 467px;    
                left: 0;
                @media(max-width: $md4+px) {
                    top: 2px;
                    left: 62px;
                    width: 56px;
                }
                @media(max-width: $md6+px) {
                    width: 62px;
                    top: 9px;
                    left: 37px;
                }
            }
            &_02 {
                width: 109px;
                top: 186px;
                left: 44px;
                @media(max-width: $md4+px) {
                    top: -20px;
                    left: 0;
                    right: -300px;
                    margin: 0 auto;
                    width: 65px;
                }
                @media(max-width: 700px) {
                    right: -140px;
                }
                @media(max-width: $md6+px) {
                    top: 20px;
                    right: 18px;
                    left: unset;
                    width: 50px;
                }
            }
            &_03 {
                width: 65px;
                top: 87px;
                left: 49px;
                @media(max-width: $md4+px) {
                    top: 0;
                    right: 107px;
                    width: 40px;
                    left: unset;
                }
                @media(max-width: $md6+px) {
                    right: 71px;
                }
            }
            &_04 {
                width: 87px;
                top: 163px;
                right: 54px; 
                @media(max-width: $md4+px) {
                    width: 50px;
                    top: unset;
                    bottom: 36px;
                    left: 0;
                    margin: 0 auto;
                    right: -390px;
                }
                @media(max-width: $md6+px) {
                    bottom: 16px;
                    left: 95px;
                    right: unset;
                }
            }
            &_05 {
                width: 103px;
                top: 550px;
                right: 13px;
                @media(max-width: $md4+px) {
                    bottom: 16px;
                    top: unset;
                    width: 65px;
                    left: 134px;
                }
                @media(max-width: $md6+px) {
                    left: 16px;
                    bottom: 14px;
                    width: 50px;
                }
            }
            .icon {
                width: 100%;
            }
        }
    }
}