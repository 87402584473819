@import '../../../styles/var';

.benefits {
    position: relative;
    z-index: 10;
    .title {
        max-width: 750px;
        margin: 0 auto;
        text-align: center;
    }
    .cards {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        justify-items: center;
        gap: 20px;
        margin-top: 75px;
        @media(max-width: $md3+px) {
            grid-template-columns: repeat(3, 1fr);
            gap: 65px 20px;
        }
        @media(max-width: $md4+px) {
            margin-top: 65px;
        }
        @media(max-width: 600px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 50px 20px;
        }
        .card {
            text-align: center;
            &:hover {
                .cardIcon {
                    transform: translateY(-10px);
                }
            }
            &Icon {
                height: 50px;
                transition: all .3s;
                @media(max-width: $md6+px) {
                    height: 40px;
                }
            }
            &Text {
                margin-top: 20px;
            }
        }
    }
    .btn {
        text-align: center;
        margin-top: 50px;
    }
}