@import "../../../styles/var";

.quick {
}
.quickBody {
}
.quickTitle {
    margin-bottom: 30px;
}
.quickRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}
.quickRowLeft {
    flex: 0 1 525px;
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
    }
}
.quickRowLeftText {
    margin-bottom: 15px;
    @media (max-width: $md3+px) {
        max-width: 683px;
    }
}
.quickRowLeftList {
}
.quickRowLeftListItem {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    img {
        margin-right: 10px;
    }
}
.quickRowRight {
    flex: 0 1 525px;
    @media (max-width: $md3+px) {
        flex: 0 1 683px;
    }
}
.quickRowRightText {
    margin-bottom: 15px;
}
.quickRowRightBtn {
    margin-top: 50px;
}
