@import '../../../styles/var';

.underBanner {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 446px;
    overflow: hidden;
    background: #F4F4F4;
    max-width: 100%;
    margin: 0 auto;
    @media(max-width: 1520px) {
        max-width: 1520px;
    }
    @media(max-width: $md4+px) {
        display: none;
    }
    .bgWrap {
        position: absolute;
        top: -185px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(5, 1fr);
        gap: 20px;
        transform: rotate(30deg) scale(1.2);
        left: calc(50vw - 2225px / 2 + 280px);
        @media(max-width: 1520px) {
            left: calc(50vw - 2225px / 2 + 265px);
            transform: rotate(30deg);
        }
        .lineWrap {
            width: 2225px;
            height: 215px;
            &_01 {
                transform: translateX(945px);
            }
            &_02 {
                transform: translateX(-580px);
            }
            &_03 {
                transform: translateX(260px);
            }
            &_04 {
                transform: translateX(-1220px);
            }
            &_05 {
                transform: translateX(120px);
            }
            .line {
                width: 100%;
                height: 100%;
            }
        }
    }
}